<template>
  <product title="Amazfit 赤道"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="299"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'equator',
  components: {
    Product
  },
  data () {
    return {
      slogan: '黑色不锈钢+TPU材质腕带',
      colors: [
        '黑色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/equator/swiper/1.png',
          CDN_URL + '/images/product/equator/swiper/2.png',
          CDN_URL + '/images/product/equator/swiper/3.png',
          CDN_URL + '/images/product/equator/swiper/4.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        CDN_URL + '/images/product/equator/detail/01.jpg',
        CDN_URL + '/images/product/equator/detail/02.jpg',
        CDN_URL + '/images/product/equator/detail/03.jpg',
        CDN_URL + '/images/product/equator/detail/04.jpg',
        CDN_URL + '/images/product/equator/detail/05.jpg',
        CDN_URL + '/images/product/equator/detail/06.jpg',
        CDN_URL + '/images/product/equator/detail/07.jpg',
        CDN_URL + '/images/product/equator/detail/08.jpg',
        CDN_URL + '/images/product/equator/detail/09.jpg',
        CDN_URL + '/images/product/equator/detail/10.jpg',
        CDN_URL + '/images/product/equator/detail/11.jpg',
        CDN_URL + '/images/product/equator/detail/12.jpg',
        CDN_URL + '/images/product/equator/detail/13.jpg',
        CDN_URL + '/images/product/equator/detail/14.jpg',
        CDN_URL + '/images/product/equator/detail/15.jpg',
        CDN_URL + '/images/product/equator/detail/16.jpg',
        CDN_URL + '/images/product/equator/detail/17.jpg'
      ],
      paramImages: [
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
